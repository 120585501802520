import domReady from '../../shared/utils/dom_ready';

domReady(() => {
  const overlay = document.querySelector('.overlay');

  if (overlay) {
    const overlayContainer = overlay.querySelector('.overlay__container');
    const overlayClose = overlayContainer.querySelector('.overlay__close');
    const overlayLinks = document.querySelectorAll('[data-overlay-link]');
    const overlayContent = document.querySelectorAll('[data-overlay]');

    const overlayShow = (el) => {
      overlay.style.display = 'flex';
      overlay.classList.add('overlay--is-active');
      el.style.display = 'block';
    };

    const overlayHide = () => {
      overlay.classList.remove('overlay--is-active');
      setTimeout(() => {
        overlay.style.display = 'none';
        overlayContent.forEach((el) => {
          el.style.display = 'none';
        });
      }, 300);
    };

    overlayLinks.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const target = el.getAttribute('data-overlay-link');
        const targetEl = document.querySelector(`[data-overlay = ${target}]`);
        overlayShow(targetEl);
      });
    });

    overlayClose.addEventListener('click', () => {
      overlayHide();
    });

    overlay.addEventListener('click', (event) => {
      if (!overlayContainer.contains(event.target)) {
        overlayHide();
      }
    });
  }
});
