import domReady from '../../shared/utils/dom_ready';

const UPDATE_INTERVAL = 2000;

domReady(() => {
  const el = document.querySelector('.one-million');

  if (!el) return;

  const { apiPath } = el.dataset;

  if (!apiPath) return;

  const valueEl = el.querySelector('.one-million__count--applicants');

  let isInViewport;
  let isUpdating;
  let timeout;
  let observer;

  const renderNumber = (number) => {
    // hook into running animation
    valueEl.changeFinalNumber(number);
    // or set directly (it's fine to do both)
    valueEl.innerText = number.toLocaleString('de');
  };

  const stop = () => {
    observer.disconnect();
    // eslint-disable-next-line no-use-before-define
    document.removeEventListener('visibilitychange', update);
  };

  const update = () => {
    clearTimeout(timeout);

    if (document.hidden || !isInViewport || isUpdating) return;

    isUpdating = true;

    fetch(apiPath)
      .then((response) => {
        if (!response.ok) {
          // throw and don't update the number
          throw new Error('API unavailable');
        }

        return response.json();
      })
      .then((data) => {
        renderNumber(data);

        if (!document.hidden && isInViewport) {
          timeout = setTimeout(update, UPDATE_INTERVAL);
        }

        isUpdating = false;
      })
      .catch(stop);
  };

  // update whenever entering or leaving viewport
  observer = new IntersectionObserver((entries) => {
    isInViewport = entries[0].isIntersecting;
    update();
  });
  observer.observe(el);

  // update whenever tab goes to foreground or background
  document.addEventListener('visibilitychange', update);
});
