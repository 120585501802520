import domReady from '../utils/dom_ready';

const measureHeight = (el) => {
  const height = el.scrollHeight;
  return `${height}px`;
};

const itemBody = (item) =>
  item.querySelector('.accordion__body, [data-accordion-item-body]');
const itemHead = (item) =>
  item.querySelector('.accordion__head, [data-accordion-item-head]');
const itemIsOpen = (item) => item.matches('.is-open');

const openItem = (item) => {
  item.classList.add('is-open');
  const body = itemBody(item);
  const height = measureHeight(body);
  body.style.height = 0;
  setTimeout(() => {
    body.style.height = height;
    setTimeout(() => {
      body.style.height = 'auto';
    }, 300);
  }, 100);
};

const closeItem = (item) => {
  item.classList.remove('is-open');
  const body = itemBody(item);
  body.style.height = `${body.clientHeight}px`;
  setTimeout(() => {
    body.style.height = 0;
  }, 100);
};

domReady(() => {
  const items = document.querySelectorAll(
    '.accordion__item, [data-accordion-item]'
  );

  items.forEach((item) => {
    itemHead(item).addEventListener('click', (e) => {
      if (itemIsOpen(item)) {
        closeItem(item);
      } else {
        openItem(item);
      }
      e.preventDefault();
    });
  });

  const accordionDeepLink = () => {
    if (typeof URLSearchParams === 'undefined') return;
    const params = new URLSearchParams(window.location.search);
    if (!params.has('item')) return;
    const item = params.get('item').split('_');
    const itemIndex = item[1] - 1;
    if (items[itemIndex]) {
      openItem(items[itemIndex]);
    }
  };
  accordionDeepLink();
});
