/**
 * Calls the callback once as soon as the element enters the viewport.
 *
 * @param {HTMLElement} el
 * @param {Function} callback
 */

const inViewport = (el, callback) => {
  if (typeof IntersectionObserver === 'undefined') return;

  new IntersectionObserver((entries, observer) => {
    if (entries[0].isIntersecting) {
      callback();
      observer.disconnect();
    }
  }).observe(el);
};

export default inViewport;
