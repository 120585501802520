import domReady from '../../shared/utils/dom_ready';
import inViewport from '../../shared/utils/in_viewport';

domReady(() => {
  const titleBand = document.querySelectorAll(
    '.title-band:not(.journal-detail__title)'
  );

  titleBand.forEach((item) => {
    inViewport(item, () => {
      const titleBandSpans = item.querySelectorAll('span');

      titleBandSpans.forEach((el, i) => {
        const atSameLevel =
          i > 0 &&
          titleBandSpans[i - 1].getBoundingClientRect().top ===
            el.getBoundingClientRect().top;
        const delay = (atSameLevel ? i - 1 : i) * 100 + 300;
        el.style.transition = `transform 0.4s ease-out ${delay}ms`;
        el.style.transform = 'translateX(0)';
      });
    });
  });
});
