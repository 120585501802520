import domReady from '../../shared/utils/dom_ready';

domReady(() => {
  const form = document.querySelector('[data-sync-to-local-storage]');

  if (!form) return;

  const storageId = `${form.dataset.token}-${form.dataset.page}`;
  const storedValues = JSON.parse(localStorage.getItem(storageId));

  if (storedValues) {
    form
      .querySelectorAll('select, input:not([type=hidden])')
      .forEach(inputEl => {
        const storedValue = storedValues.form[inputEl.id];

        if (storedValue) {
          if (['radio', 'checkbox'].includes(inputEl.type)) {
            if (inputEl.value === storedValue.formValue) inputEl.checked = true;
          } else {
            inputEl.value = storedValue.formValue;
          }
        }
      });
  }

  form.addEventListener(
    'submit',
    e => {
      if (form.valid) {
        const content = {
          form: {},
          orderedFormKeys: [],
          title: document.querySelector('h1').innerText,
          editLinkPath: form.dataset.currentPath
        };

        form
          .querySelectorAll('select, input:not([type=hidden])')
          .forEach(inputEl => {
            if (['radio', 'checkbox'].includes(inputEl.type)) {
              if (inputEl.checked) {
                const label =
                  inputEl.parentElement.querySelector('legend') ||
                  inputEl.parentElement;

                content.form[inputEl.id] = {
                  formKey: inputEl.name,
                  formValue: inputEl.value,
                  keyLabel: label.dataset.label || label.innerText,
                  valueLabel: inputEl.nextElementSibling.innerText
                };
                content.orderedFormKeys.push(inputEl.id);
              }
            } else {
              const label = inputEl.parentElement.querySelector('label');

              content.form[inputEl.id] = {
                formKey: inputEl.id,
                formValue: inputEl.value,
                keyLabel:
                  (label && label.dataset.label) || (label && label.innerText),
                valueLabel: inputEl.matches('select')
                  ? inputEl.options[inputEl.selectedIndex].innerText
                  : inputEl.value
              };
              content.orderedFormKeys.push(inputEl.id);
            }
          });

        localStorage.setItem(storageId, JSON.stringify(content));
        window.location = form.dataset.targetPath;
      }

      e.preventDefault();
    },
    false
  );
});
