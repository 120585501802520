import { createPopper } from '@popperjs/core';
import domReady from '../../shared/utils/dom_ready';

const removeTooltip = () => {
  const el = document.querySelector('.tooltip');
  if (el) el.remove();
};

const positionTooltip = (el, tooltip, arrow) => {
  createPopper(el, tooltip, {
    placement: 'top',
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrow,
          padding: 48,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10],
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'bottom'],
        },
      },
    ],
  });
};

const createTooltip = (el) => {
  const fragment = new DocumentFragment();

  const tooltip = document.createElement('div');
  tooltip.className = 'tooltip';
  fragment.appendChild(tooltip);

  const body = document.createElement('div');
  body.className = 'tooltip__body';
  tooltip.appendChild(body);

  const title = el.dataset.tooltipTitle;
  if (title) {
    const titleEl = document.createElement('strong');
    titleEl.innerText = title;
    body.appendChild(titleEl);
  }

  const text = el.dataset.tooltipText;
  if (text) {
    const textEl = document.createElement('div');
    textEl.innerHTML = text;
    body.appendChild(textEl);
  }

  const arrow = document.createElement('div');
  arrow.className = 'tooltip__arrow';
  tooltip.appendChild(arrow);

  const arrowInner = document.createElement('div');
  arrow.appendChild(arrowInner);

  document.body.appendChild(fragment);

  positionTooltip(el, tooltip, arrow);
};

domReady(() => {
  const elements = document.querySelectorAll(
    '[data-tooltip-title], [data-tooltip-text]'
  );

  elements.forEach((el) => {
    el.addEventListener(
      'click',
      (e) => {
        removeTooltip();
        createTooltip(el);
        e.preventDefault();
        e.stopPropagation();
      },
      false
    );
  });

  if (elements.length > 0) {
    document.addEventListener('click', removeTooltip, false);
  }
});
