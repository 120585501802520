import domReady from '../utils/dom_ready';
import scrollit from '../utils/scrollit';

domReady(() => {
  const anchorTargets = document.querySelectorAll('[js_anchor_target]');
  const fixedHeader = document.querySelector('[data-fixed-header]');
  const hasFixedHeader = document.body.contains(fixedHeader);

  if (anchorTargets.length) {
    anchorTargets.forEach((el) => {
      const targetValue = el.getAttribute('js_anchor_target');
      const targetItem = document.querySelector(
        `[js_anchor = "${targetValue}"]`
      );
      el.addEventListener('click', (e) => {
        scrollit(targetItem, 600, hasFixedHeader);
        e.preventDefault();
      });
    });
  }
});
