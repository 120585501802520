/*

scrollit instructions:

destination:
  this is either the queried selector to which you want to scroll to, or a pixel value
duration:
  the duration of the animated scroll
nav:
  true or false if page has navigation bar or not
extra (optional):
  should the scroll go a little further or less, place pixel value here

example:
  let anchor = document.querySelector('.target');
  scrollit(anchor, 600, true, 30);

*/

const scrollit = (destination, duration, nav, extra) => {
  Math.easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) {
      // eslint-disable-next-line no-mixed-operators
      return (c / 2) * t * t + b;
    }
    t -= 1;
    // eslint-disable-next-line no-mixed-operators
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const s = {};
  const header = document.querySelector('header');
  s.navOffset = header && nav ? header.getBoundingClientRect().height : 0;
  s.extraOffset = extra || 0;

  if (typeof destination === 'number') {
    s.destinationOffset = destination;
  } else {
    s.destinationOffset =
      destination.getBoundingClientRect().top - s.navOffset - s.extraOffset;
  }

  s.bodyOffset =
    document.querySelector('body').getBoundingClientRect().top * -1;
  s.scrollPos = s.destinationOffset + s.bodyOffset;
  s.start = window.scrollY;
  s.change = s.scrollPos - s.start;
  s.currentTime = 0;
  s.increment = 20;
  s.duration = duration;

  s.animateScroll = () => {
    s.currentTime += s.increment;
    s.amount = Math.easeInOutQuad(s.currentTime, s.start, s.change, s.duration);
    window.scrollTo(0, s.amount);
    if (s.currentTime < s.duration) {
      window.requestAnimationFrame(s.animateScroll);
    }
  };

  s.animateScroll();
};

export default scrollit;
