import domReady from '../../shared/utils/dom_ready';

domReady(() => {
  const blurryBackground = document.querySelector('.blurry-background');

  if (!blurryBackground) return;

  const blurryOverlay = document.querySelector('.blurry-overlay');

  const hideBackground = () => {
    blurryBackground.classList.remove('blurry-background');
    blurryBackground.classList.remove('blurry-background--fade-out');
  };

  const hideOverlay = () => {
    blurryOverlay.style.display = 'none';
  };

  const hide = () => {
    hideBackground();
    hideOverlay();
  };

  const fadeOutBackground = () => {
    blurryBackground.classList.add('blurry-background--fade-out');
    blurryBackground.addEventListener('transitionend', hideBackground, false);
  };

  const fadeOutOverlay = () => {
    blurryOverlay.classList.add('blurry-overlay--fade-out');
    blurryOverlay.addEventListener('transitionend', hideOverlay, false);
  };

  setTimeout(fadeOutOverlay, 2600);
  setTimeout(fadeOutBackground, 2500);

  blurryOverlay.addEventListener('click', hide, false);
});
