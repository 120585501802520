import domReady from '../../shared/utils/dom_ready';
import inViewport from '../../shared/utils/in_viewport';

const translations = {
  pilot: {
    cookie_consent: {
      accept: 'Video starten',
      dismiss: 'Abbrechen',
      info: 'Durch das Starten des Videos willigen Sie ein, dass automatisch Marketing-Cookies gesetzt werden. Ihre Einwilligung ist aus datenschutzrechtlichen Gründen notwendig. Sie können sie jederzeit auf der Seite <a class="link link--inline" href="/info/cookie-einstellungen">Cookie-Einstellungen</a> rückgängig machen.',
      title: 'Zulassen von Cookies',
    },
  },
};

domReady(() => {
  const loadYoutubeApi = () => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  };

  const videoLoadContainers = Array.from(
    document.querySelectorAll('[videoload]')
  );

  const initializeVideo = () => {
    loadYoutubeApi();
    const videoPlayers = [];
    const videoPreviews = [];
    const playerObject = {};

    const onPlayerReady = (event) => {
      const videoOverlays = document.querySelectorAll('.video-overlay');
      if (videoOverlays.length) {
        videoOverlays.forEach((el) => {
          el.addEventListener(
            'overlayHide',
            () => {
              event.target.pauseVideo();
            },
            false
          );
        });
      }

      if (videoPreviews.length) {
        videoPlayers.push(event.target);
        videoPlayers.forEach((el, index) => {
          if (videoPreviews[index]) {
            videoPreviews[index].addEventListener('click', () => {
              videoPreviews[index].style.zIndex = -1;
              el.playVideo();
            });
          }
        });
      }
    };

    window.onYouTubePlayerAPIReady = () => {
      videoLoadContainers.forEach((el) => {
        inViewport(el, () => {
          const videoYouTubeId = el.getAttribute('data-videoid');
          const videoPreview = el.parentNode.parentNode.querySelector(
            '.video-preview'
          );
          if (videoPreview) {
            videoPreviews.push(videoPreview);
          }
          playerObject.youTubePlayer = new YT.Player(el, {
            host: 'https://www.youtube-nocookie.com',
            videoId: videoYouTubeId,
            playerVars: { rel: 0, playsinline: 1 },
            events: {
              onReady: onPlayerReady,
            },
          });
        });
      });
    };
  };

  const videoOverlayShow = (el) => {
    el.style.display = 'flex';
    el.classList.add('video-overlay--is-active');
  };

  // cookie consent modal
  let cookieModalModal;
  let cookieModalContainer;
  let cookieModalDismiss;
  let cookieModalAccept;
  let cookieModalExists = false;
  let cookieModalConsented = false;

  const cookieModalCustomConsent = () => {
    Cookiebot.submitCustomConsent(
      Cookiebot.consent.preferences,
      Cookiebot.consent.statistics,
      true
    );
    Cookiebot.hide();
  };

  const cookieModalBuild = () => {
    const cookieModalHTML = `<div class='js-consent-modal'>
      <div class="js-consent-modal__container">
        <div class="js-consent-modal__close js-consent-modal__dismiss"></div>
        <h3 class='js-consent-modal__heading mb-4'>
          ${translations.pilot.cookie_consent.title}
        </h3>
        <div class='js-consent-modal__content'>
          ${translations.pilot.cookie_consent.info}
        </div>
        <div class='js-consent-modal__buttons'>
          <div class='js-consent-modal__accept button'>
            ${translations.pilot.cookie_consent.accept}
          </div>
          <div class='js-consent-modal__dismiss button button--secondary'>
            ${translations.pilot.cookie_consent.dismiss}
          </div>
        </div>
      </div>
    </div>`;

    const cookieModalFragment = document
      .createRange()
      .createContextualFragment(cookieModalHTML);
    cookieModalModal = cookieModalFragment.querySelector('.js-consent-modal');
    cookieModalContainer = cookieModalFragment.querySelector(
      '.js-consent-modal__container'
    );
    cookieModalAccept = cookieModalFragment.querySelector(
      '.js-consent-modal__accept'
    );
    cookieModalDismiss = cookieModalFragment.querySelectorAll(
      '.js-consent-modal__dismiss'
    );
    cookieModalExists = true;
    document.querySelector('body').appendChild(cookieModalFragment);
  };

  const cookieModalShow = (el) => {
    if (Cookiebot.consent.marketing) {
      return;
    }
    if (!cookieModalExists) {
      cookieModalBuild();
      cookieModalModal.addEventListener('click', (event) => {
        if (!cookieModalContainer.contains(event.target)) {
          cookieModalModal.classList.remove('js-consent-modal--is-active');
        }
      });
    } else {
      cookieModalModal.classList.add('js-consent-modal--is-active');
    }

    cookieModalDismiss.forEach((item) => {
      item.addEventListener('click', () => {
        cookieModalModal.classList.remove('js-consent-modal--is-active');
      });
    });

    cookieModalAccept.addEventListener('click', () => {
      cookieModalModal.remove();
      cookieModalCustomConsent();
      cookieModalConsented = true;
      const target = el.getAttribute('data-video-overlay-link');
      const targetEl = document.querySelector(
        `[data-video-overlay = ${target}]`
      );
      videoOverlayShow(targetEl);
    });
  };

  const videoContainers = document.querySelectorAll('.video-preview');

  if (videoContainers.length) {
    videoContainers.forEach((item) => {
      inViewport(item, () => {
        item.classList.add('video-preview--zoom');
      });
    });
  }

  const cookieCheck = () => {
    if (!Cookiebot.consent.marketing && !cookieModalConsented) {
      videoContainers.forEach((el) => {
        el.addEventListener('click', () => {
          cookieModalShow(el);
        });
      });
    } else {
      initializeVideo();
    }
  };

  // cookiebot events
  if (Cookiebot && Cookiebot.consent) {
    if (videoLoadContainers.length) {
      cookieCheck();
    }
  }

  window.addEventListener('CookiebotOnDialogInit', () => {
    if (videoLoadContainers.length) {
      cookieCheck();
    }
  });

  window.addEventListener('CookiebotOnLoad', () => {
    if (videoLoadContainers.length) {
      cookieCheck();
    }
  });

  const videoOverlays = document.querySelectorAll('.video-overlay');
  const hideEvent = new Event('overlayHide');

  if (videoOverlays.length) {
    videoOverlays.forEach((item) => {
      const videoOverlayContainer = item.querySelector(
        '.video-overlay__container'
      );
      const videoOverlayClose = item.querySelector('.video-overlay__close');
      const videoOverlayLink = document.querySelector(
        `[data-video-overlay-link = ${item.dataset.videoOverlay}]`
      );

      const videoOverlayHide = () => {
        item.dispatchEvent(hideEvent);
        item.classList.remove('video-overlay--is-active');
        setTimeout(() => {
          item.style.display = 'none';
        }, 300);
      };

      videoOverlayLink.addEventListener('click', (e) => {
        if (!Cookiebot.consent.marketing) {
          return;
        }
        e.preventDefault();
        videoOverlayShow(item);
      });

      videoOverlayClose.addEventListener('click', () => {
        videoOverlayHide();
      });

      item.addEventListener('click', (event) => {
        if (!videoOverlayContainer.contains(event.target)) {
          videoOverlayHide();
        }
      });
    });
  }
});
