/**
 * Render applicant data from localStorage.
 */
import domReady from '../../shared/utils/dom_ready';

const getData = (rootEl) => {
  const data = [];

  [2, 3, 4].forEach((page) => {
    const storedData = JSON.parse(
      window.localStorage.getItem(`${rootEl.dataset.token}-${page}`)
    );
    if (storedData) {
      data.push(storedData);
    } else {
      // when data is incomplete go back to start form
      window.location = rootEl.dataset.startPath;
    }
  });

  return data;
};

const renderListItem = (list, key, value) => {
  const keyEl = document.createElement('dt');
  keyEl.innerText = key;
  list.appendChild(keyEl);

  const valueEl = document.createElement('dd');
  valueEl.innerText = value;
  list.appendChild(valueEl);
};

const fillFormField = (key, value) => {
  const field = document.getElementById(`applicant_${key}`);
  if (field) field.value = value;
};

const renderPageSummary = (rootEl, data) => {
  // wrapper
  const el = document.createElement('div');
  el.className = 'summary-list';

  // title
  const title = document.createElement('strong');
  title.className = 'summary-list__title';
  title.innerText = data.title;
  el.appendChild(title);

  // list
  const list = document.createElement('dl');
  list.className = 'summary-list__entries';

  // list items
  data.orderedFormKeys.forEach((key) => {
    const entryData = data.form[key];

    if (entryData.formKey === 'income_amount') {
      // income_amount
      const value = `${entryData.valueLabel} €`;
      renderListItem(list, entryData.keyLabel, value);
      fillFormField('income_amount', entryData.formValue);
    } else if (entryData.formKey === 'social_benefits_selection') {
      // social benefits
      if (entryData.formValue === 'true') {
        const keys = data.orderedFormKeys.filter(
          (k) =>
            k.match(/social_benefits_/) && !k.match(/social_benefits_selection/)
        );
        const otherSocialBenefits = data.form.other_social_benefits;
        const values = keys.map((k) => {
          // sneak in other social benefits
          if (
            otherSocialBenefits &&
            otherSocialBenefits.formValue &&
            k === 'social_benefits_other'
          ) {
            return `${data.form[k].valueLabel} (${otherSocialBenefits.formValue})`;
          }
          return data.form[k].valueLabel;
        });

        renderListItem(list, entryData.keyLabel, values.join(', '));

        // create a hidden input field for every selected social benefit
        const form = document.querySelector('form.edit_applicant');
        const formValues = keys.map((k) => data.form[k].formValue);
        formValues.forEach((v) => {
          const field = document.createElement('input');
          field.type = 'hidden';
          field.name = 'applicant[social_benefits][]';
          field.value = v;
          form.appendChild(field);
        });
      } else {
        // no social benefits selected
        renderListItem(list, entryData.keyLabel, entryData.valueLabel);
      }
    } else if (entryData.formKey === 'other_social_benefits') {
      // other social benefits text input
      fillFormField(entryData.formKey, entryData.formValue);
    } else if (!entryData.formKey.match(/social_benefits_/)) {
      // default case
      renderListItem(list, entryData.keyLabel, entryData.valueLabel);
      fillFormField(entryData.formKey, entryData.formValue);
    }
  });
  el.appendChild(list);

  // edit link
  const editLink = document.createElement('a');
  editLink.className = 'link link--inline summary-list__edit-link';
  editLink.innerText = rootEl.dataset.editLinkLabel;
  editLink.href = data.editLinkPath;
  el.appendChild(editLink);

  return el;
};

const renderSummary = (rootEl, data) => {
  if (!data) return;

  data.forEach((pageData) => {
    rootEl.appendChild(renderPageSummary(rootEl, pageData));
  });
};

domReady(() => {
  const rootEl = document.getElementById('js-summary-page');

  if (!rootEl) return;

  renderSummary(rootEl, getData(rootEl));
});
