/**
 * Add class to the element as soon as the element enters the viewport.
 *
 * @example
 * <div data-in-viewport-add-class="in-view"> becomes <div class="in-view">
 */

import domReady from '../utils/dom_ready';
import inViewport from '../utils/in_viewport';

domReady(() => {
  document.querySelectorAll('[data-in-viewport-add-class]').forEach((el) => {
    inViewport(el, () => el.classList.add(el.dataset.inViewportAddClass));
  });
});
