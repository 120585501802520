/**
 * Ruby on Rails unobstrusive scripting adapter
 *
 * Features:
 * - force confirmation dialogs for various actions
 * - make non-GET requests from hyperlinks
 * - make forms or hyperlinks submit data asynchronously with Ajax
 * - have submit buttons become automatically disabled on form submit
 *   to prevent double-clicking
 *
 * These features are achieved by adding certain data attributes to your HTML
 * markup. In Rails, they are added by the framework's template helpers.
 *
 * @see
 * https://github.com/rails/rails/tree/master/actionview/app/assets/javascripts
 */
import ujs from '@rails/ujs';

ujs.start();
