import domReady from '../../shared/utils/dom_ready';

const update = (el, requiredElement) => {
  const requiredElementChecked = requiredElement.matches(':checked');
  el.style.display = requiredElementChecked ? 'none' : 'block';
};

domReady(() => {
  const elements = document.querySelectorAll('[data-hide-if-checked]');

  elements.forEach(el => {
    const requiredElement = document.getElementById(el.dataset.hideIfChecked);

    requiredElement.addEventListener(
      'change',
      () => {
        update(el, requiredElement);
      },
      false
    );

    setTimeout(() => {
      update(el, requiredElement);
    }, 1);
  });
});
