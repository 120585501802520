// polyfills
import './polyfills/for_each_polyfill';

// shared libs
import '../shared/lib/rails';

// libs
import 'bootstrap.native';
import Swiper, { Navigation, Pagination } from 'swiper';

// shared components
import '../shared/components/accordion';
import '../shared/components/animate_counter';
import '../shared/components/form_validation';
import '../shared/components/in_viewport_add_class';
import '../shared/components/scroll_to_anchor';
import '../shared/components/sepa_format';
import '../shared/components/telephone_format';
import '../shared/components/newsletter_subscription';

// components
import './components/check_if_checked';
import './components/collapse';
import './components/delete_local_applicant_data';
import './components/debit_mandate_configurator';
import './components/debit_mandate_goal_tracking';
import './components/email_successfully_confirmed';
import './components/faq';
import './components/header';
import './components/hide_if_checked';
import './components/journal';
import './components/one_million';
import './components/overlay';
import './components/quiz';
import './components/show_if_checked';
import './components/sticky_button';
import './components/summary_page';
import './components/sync_form_to_localstorage';
import './components/tabs';
import './components/title_band';
import './components/tooltip';
import './components/uncheck_if_checked';
import './components/url_parameter_scroll_to';
import './components/video';

/* eslint-disable-next-line */
new Swiper('[data-controller="carousel"]', {
  modules: [Navigation, Pagination],
  speed: 500,
  autoHeight: true,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  a11y: {
    enabled: true,
    prevSlideMessage: 'vorherige',
    nextSlideMessage: 'nächste',
  },
});
