import domReady from '../../shared/utils/dom_ready';

domReady(() => {
  const omqContainer = document.getElementById('omq-help-container');
  if (!omqContainer) return;
  if (window.location.search.includes('question=')) {
    omqContainer.classList.add('single-question');
    setTimeout(() => {
      const navLink = omqContainer.querySelector('.omq-help-navigation__link');
      if (navLink) {
        navLink.addEventListener('click', () => {
          omqContainer.classList.remove('single-question');
        });
      }
    }, 1000);
  }
});
