import domReady from '../utils/dom_ready';

// minimum duration of the loading animation
// (bad UX when it only shows up for milliseconds)
const minAnimationDuration = 2000;

let requestStartTime;
let subscriptionForm;

// enable transition of width and height of confirm button
const growButton = () => {
  const growButtonOrigin = subscriptionForm.querySelector('[data-grow-origin]');
  if (!growButtonOrigin) return;
  const growButtonTarget = subscriptionForm.querySelector('[data-grow-target]');
  const initialWidth = growButtonOrigin.offsetWidth;
  const initialHeight = growButtonOrigin.offsetHeight;
  growButtonOrigin.style.display = 'none';
  growButtonTarget.style.display = 'inline-block';
  const finalWidth = growButtonTarget.offsetWidth;
  const finalHeight = growButtonTarget.offsetHeight;
  growButtonTarget.style.width = `${initialWidth}px`;
  growButtonTarget.style.height = `${initialHeight}px`;
  setTimeout(() => {
    growButtonTarget.style.width = `${finalWidth}px`;
    growButtonTarget.style.height = `${finalHeight}px`;
    growButtonTarget.children[0].style.opacity = 1;
  }, 100);
  window.addEventListener('resize', () => {
    growButtonTarget.style.width = 'auto';
    growButtonTarget.style.height = 'auto';
  });
}

// render final state of form
const renderCompleted = () => {
  subscriptionForm.classList.add('sent');
  growButton();
}

// when the request (newsletter subscription) was successful
const onSuccess = () => {
  const timeElapsed = Date.now() - requestStartTime;

  if (timeElapsed >= minAnimationDuration) {
    renderCompleted();
  } else {
    // the request was faster, but we still want to show the animation for a bit
    const remainingAnimationDuration = minAnimationDuration - timeElapsed;
    setTimeout(renderCompleted, remainingAnimationDuration);
  }

  // cleanup global event listener
  document.body.removeEventListener('ajax:success', onSuccess);

  // fathom tracking of successful submission
  if (window.fathom && window.fathom.trackGoal) {
    window.fathom.trackGoal('XDVWXMMR', 0);
  }
};

domReady(() => {
  subscriptionForm = document.querySelector('#new_subscription');

  if (!subscriptionForm) return;

  subscriptionForm.addEventListener('submit', () => {
    // form validation happens before
    if (!subscriptionForm.valid) return;

    subscriptionForm.classList.add('sending');
    requestStartTime = Date.now();

    // the request itself is carried out by Rails UJS, which emits an event on success
    document.body.addEventListener('ajax:success', onSuccess);

    // disable the input field after the Rails UJS request,
    // because disabled fields are ignored by UJS
    setTimeout(() => {
      subscriptionForm.querySelector('input[type=email]').disabled = true;
    }, 1);
  });
});
