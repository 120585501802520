import domReady from '../../shared/utils/dom_ready';

const update = (el, requiredElements) => {
  const requiredElementChecked = requiredElements.some((r) =>
    r.matches(':checked')
  );
  if (requiredElementChecked) el.checked = true;
};

domReady(() => {
  const elements = document.querySelectorAll('[data-check-if-checked]');

  elements.forEach((el) => {
    const id = el.dataset.checkIfChecked;
    const requiredElements = [
      ...document.querySelectorAll(`#${id} input[type='checkbox']`),
    ];

    update(el, requiredElements);

    requiredElements.forEach((requiredEl) => {
      requiredEl.addEventListener(
        'change',
        () => {
          update(el, requiredElements);
        },
        false
      );
    });
  });
});
