import domReady from '../../shared/utils/dom_ready';

let navIsOpen = false;

const closeMobileNavigation = (header) => {
  header.classList.remove('is-open');
  header.classList.remove('is-opening');
  header.classList.add('is-closing');
  setTimeout(() => {
    header.classList.remove('is-closing');
    navIsOpen = false;
  }, 200);
};

domReady(() => {
  const header = document.getElementById('header');
  if (!header) return;

  const headerBar = document.querySelector('.header__bar');
  const burgerMenu = document.getElementById('header-nav-toggle');
  const navSlideOut = document.getElementById('header-nav');

  burgerMenu.addEventListener(
    'click',
    (e) => {
      e.preventDefault();
      navSlideOut.style.display = 'block';

      setTimeout(() => {
        if (header.matches('.is-open')) {
          closeMobileNavigation(header);
        } else {
          header.classList.add('is-open');
          header.classList.add('is-opening');
          header.classList.remove('is-closing');
          navIsOpen = true;
          setTimeout(() => {
            header.classList.remove('is-opening');
          }, 350);
        }
      }, 1);
    },
    false
  );

  const buttons = document.querySelectorAll(
    '.header__mobile-nav-items .button'
  );

  buttons.forEach((button, index) => {
    const fullWidth = window.innerWidth;
    const buttonWidth = button.offsetWidth;
    const fullTime = 500;
    const time = (fullTime / fullWidth) * buttonWidth;
    button.style.transitionDuration = `${time}ms`;
    button.style.transitionDelay = `${10 * index + 150}ms`;

    button.addEventListener(
      'click',
      (e) => {
        closeMobileNavigation(header);
        e.preventDefault();
        setTimeout(() => {
          window.location = button.href;
        }, 250);
      },
      false
    );
  });

  const subMenuToggles = document.querySelectorAll(
    '.header__link--toggle-sub-menu'
  );

  const subMenu = document.querySelectorAll(
    '.header__sub-menu'
  );

  const headerLinks = document.querySelectorAll(
    '.header__link:not(.header__link--toggle-sub-menu)'
  );

  const closeMenu = () => {
    const openMenu = document.querySelector('.header__links .is-open');
    if (openMenu) openMenu.classList.remove('is-open');
  }

  subMenuToggles.forEach((toggle) => {
    toggle.addEventListener('click', (e) => {
      e.preventDefault();
      e.stopPropagation();
    });
    toggle.addEventListener('mouseenter', () => {
      toggle.parentElement.classList.add('is-open');
    });
  });

  subMenu.forEach((menu) => {
    menu.addEventListener('mouseleave', () => {
      menu.parentElement.classList.remove('is-open');
    });
  });

  headerLinks.forEach((link) => {
    link.addEventListener('mouseenter', () => {
      closeMenu();
    });
  });

  document.addEventListener('click', () => {
    closeMenu();
  });

  const threshold = window.innerHeight / 2;
  let pageOffset = window.pageYOffset;

  window.addEventListener('scroll', () => {
    const currentOffset = window.pageYOffset;
    const hide =
      currentOffset > threshold && currentOffset > pageOffset && !navIsOpen;
    pageOffset = currentOffset;

    if (hide) {
      headerBar.classList.add('is-hidden');
    } else {
      headerBar.classList.remove('is-hidden');
    }
  });
});
