import domReady from '../../shared/utils/dom_ready';

domReady(() => {
  const form = document.getElementById('new_debit_mandate');

  if (!form) return;

  if (typeof URLSearchParams === 'undefined') return;

  const params = new URLSearchParams(window.location.search);

  if (!params.has('page')) return;

  const goalId = params.get('page');

  if (goalId.length !== 8) return;

  const amountTotal = parseInt(
    document.getElementById('debit_mandate_amount_total').value,
    10
  );

  form.addEventListener(
    'submit',
    () => {
      if (form.validate() && window.fathom && window.fathom.trackGoal) {
        window.fathom.trackGoal(goalId, amountTotal);
      }
    },
    false
  );
});
