import domReady from '../../shared/utils/dom_ready';

domReady(() => {
  const el = document.getElementById('js-delete-local-applicant-data');

  if (!el) return;

  [2, 3, 4].forEach((page) =>
    window.localStorage.removeItem(`${el.dataset.token}-${page}`)
  );
});
