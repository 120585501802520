import IMask from 'imask';
import domReady from '../utils/dom_ready';

domReady(() => {
  const element = document.querySelector('[data-autoformat-telephone]');
  if(!element) return;
  // eslint-disable-next-line no-unused-vars
  const telephoneMask = IMask(
    element, {
      mask: '+{49 (\\0)} 000 000 000 000'
    });
});
