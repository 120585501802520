import IBAN from 'iban';
import domReady from '../utils/dom_ready';
import scrollit from '../utils/scrollit';
import capitalize from '../utils/strings/capitalize';
import dasherize from '../utils/strings/dasherize';

const CSS_CLASS_VALID = 'form-control--valid';
const CSS_CLASS_ERROR = 'error';
const CSS_CLASS_MESSAGE = 'error-message';

const DDMMYYYY = /(0[1-9]|1[0-9]|2[0-9]|3[01])\.(0[1-9]|1[012])\.[0-9]{4}/;
const VALID_EMAIL =
  /^(?!\.)[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*[.][a-zA-Z-]{2,63}$/;

const updateEl = (el, message = null) => {
  if (message) {
    el.setAttribute('aria-invalid', true);
  } else {
    el.removeAttribute('aria-invalid');
  }

  const isCheckbox = el.type === 'checkbox';

  const container = isCheckbox ? el.parentElement : el;

  let messageEl;

  if (typeof el.value === 'undefined') {
    messageEl = container.querySelector(`.${CSS_CLASS_MESSAGE}`);
  } else {
    messageEl = container.parentElement.querySelector(`.${CSS_CLASS_MESSAGE}`);
  }

  if (!isCheckbox) {
    container.classList[message ? 'remove' : 'add'](CSS_CLASS_VALID);
  }
  el.classList[message ? 'add' : 'remove'](CSS_CLASS_ERROR);

  if (messageEl && !message) {
    messageEl.remove();
  } else if (messageEl) {
    messageEl.innerHTML = message;
  } else if (message) {
    messageEl = document.createElement('div');
    messageEl.className = CSS_CLASS_MESSAGE;
    messageEl.innerHTML = message;
    if (typeof el.value === 'undefined') {
      container.appendChild(messageEl);
    } else {
      container.parentElement.appendChild(messageEl);
    }
  }
};

const convertToDate = (el) =>
  new Date(el.value.trim().split('.').reverse().join('-'));

const VALIDATIONS = [
  ['checked', (el) => el.matches(':checked')],
  ['notChecked', (el) => !el.matches(':checked')],
  ['presence', (el) => el.value.trim().length > 0],
  ['pattern', (el, param) => el.value.trim().match(new RegExp(param))],
  ['antipattern', (el, param) => !new RegExp(param).test(el.value.trim())],
  ['minLength', (el, param) => el.value.trim().length >= param],
  ['maxLength', (el, param) => el.value.trim().length <= param],
  ['date', (el) => el.value.trim().match(DDMMYYYY)],
  ['minDate', (el, param) => convertToDate(el) > new Date(param)],
  ['maxDate', (el, param) => convertToDate(el) <= new Date(param)],
  [
    'minValue',
    (el, param) => parseInt(el.value.trim(), 10) >= parseInt(param, 10),
  ],
  [
    'maxValue',
    (el, param) => parseInt(el.value.trim(), 10) <= parseInt(param, 10),
  ],
  [
    'email',
    (el) => !el.validity.typeMismatch && el.value.trim().match(VALID_EMAIL),
  ],
  [
    'iban',
    (el) => {
      if (el.value === 'DE76 4306 0967 1165 3138 00') {
        return false;
      }
      return IBAN.isValid(el.value);
    },
  ],
  [
    'notMatching',
    (el, param) => {
      const otherValue = document.getElementById(param).value.trim();
      return otherValue.length === 0 || otherValue !== el.value.trim();
    },
  ],
  [
    'matching',
    (el, param) => {
      const otherValue = document.getElementById(param).value;
      return otherValue === el.value;
    },
  ],
  ['radioChecked'],
];

const ELEMENTS = VALIDATIONS.map(
  (v) => `[data-validate-${dasherize(v[0])}]`
).join(',');

const validate = (el) => {
  let valid;
  const requiredCheckbox = el.dataset.validateOnlyIfChecked;
  const requiredSelectedValue = el.dataset.validateOnlyIfSelected;

  const isExpectedSelectedValue = () => {
    const selectId = JSON.parse(requiredSelectedValue)[0];
    const expectedValue = JSON.parse(requiredSelectedValue)[1];

    const select = document.getElementById(selectId);
    const selectedValue = select.options[select.selectedIndex].value;

    return expectedValue === selectedValue;
  };

  if (
    requiredCheckbox &&
    !document.getElementById(requiredCheckbox).matches(':checked')
  ) {
    valid = true;
  } else if (requiredSelectedValue && !isExpectedSelectedValue()) {
    valid = true;
  } else if (typeof el.value === 'undefined') {
    valid = !!el.querySelector(':checked');

    if (!valid) {
      updateEl(
        el,
        el.dataset.validateCheckedMessage ||
        el.dataset.validateRadioCheckedMessage
      );
    }
  } else {
    valid = VALIDATIONS.every((v) => {
      const property = `validate${capitalize(v[0])}`;
      const value = el.dataset[property];
      if (value && !v[1](el, value)) {
        updateEl(el, el.dataset[`${property}Message`]);
        return false;
      }
      return true;
    });
  }

  if (valid) updateEl(el);

  return valid;
};

const validateOnChange = (el) => {
  // emulate { once: true } for Internet Explorer
  if (el.eventListenerRegistered) return;
  el.eventListenerRegistered = true;

  el.addEventListener(
    el.type === 'checkbox' ||
      el.matches('select') ||
      typeof el.value === 'undefined'
      ? 'change'
      : 'blur',
    () => validate(el),
    false
  );
};

domReady(() =>
  document.querySelectorAll('form').forEach((form) => {
    const elements = form.querySelectorAll(ELEMENTS);
    if (elements.length === 0) return;

    elements.forEach((el) => {
      if (
        el.type === 'checkbox' ||
        el.matches('select') ||
        typeof el.value === 'undefined' ||
        el.value.length > 0
      ) {
        validateOnChange(el);
      } else if ('formatIban' in el.dataset) {
        let validIban;
        el.addEventListener('keydown', () => { validIban = false });
        el.addEventListener('ibanValidInput', () => { validIban = true });
        el.addEventListener('keyup', () => validIban ? updateEl(el) : validate(el));
      }
      else {
        el.addEventListener('keypress', () => validateOnChange(el), {
          once: true,
        });
      }
    });

    form.setAttribute('novalidate', true);

    const submitButtons = form.querySelectorAll('[type=submit]');
    submitButtons.forEach((el) => {
      el.removeAttribute('data-disable-with');
    });

    form.validate = () =>
      Array.from(elements).reduce(
        (valid, el) =>
          el.dataset.validateDisabled || validate(el) ? valid : false,
        true
      );

    form.addEventListener(
      'submit',
      (e) => {
        if (form.validate()) {
          form.valid = true;
          submitButtons.forEach((el) => {
            el.disabled = true;
          });
        } else {
          form.valid = false;
          e.preventDefault();
          e.stopPropagation();
          scrollit(form.querySelector(`.${CSS_CLASS_MESSAGE}`), 400, true, 120);
        }
      },
      false
    );
  })
);
