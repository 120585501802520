import domReady from '../../shared/utils/dom_ready';

const measureHeight = (el) => {
  const height = el.scrollHeight;
  return `${height}px`;
};

const collapseButton = (item) =>
  item.querySelector('.collapse__button-container');
const collapseContent = (item) => item.querySelector('.collapse__content');
const collapseIsOpen = (item) => item.matches('.is-open');

const openItem = (item) => {
  item.classList.add('is-open');
  const body = collapseContent(item);
  const height = measureHeight(body);
  body.style.height = 0;
  setTimeout(() => {
    body.style.height = height;
    setTimeout(() => {
      body.style.height = 'auto';
    }, 100);
  }, 100);
};

const closeItem = (item) => {
  item.classList.remove('is-open');
  const body = collapseContent(item);
  const height = measureHeight(body);
  body.style.height = height;
  setTimeout(() => {
    body.style.height = 0;
  }, 100);
};

domReady(() => {
  const collapseItems = document.querySelectorAll('.collapse');

  collapseItems.forEach((item) => {
    collapseButton(item).addEventListener('click', (e) => {
      if (collapseIsOpen(item)) {
        closeItem(item);
      } else {
        openItem(item);
      }

      // don't trigger checkbox when collapse
      // is used in checkbox label
      e.preventDefault();
    });
  });
});
