/*
  Pretty formatting of IBAN (actually: SEPA) input fields
*/
import IMask from 'imask';
import domReady from '../utils/dom_ready';

domReady(() => {
  const SEPA_LIST = [
    'AT00 0000 0000 0000 0000',
    'BE00 0000 0000 0000',
    'BG00 aaaa 0000 00## #### ##',
    'CH00 0000 0### #### #### #',
    'CY00 0000 0000 #### #### #### ####',
    'CZ00 0000 0000 0000 0000 0000',
    'DE00 0000 0000 0000 0000 00',
    'DK00 0000 0000 0000 00',
    'EE00 0000 0000 0000 0000',
    'ES00 0000 0000 0000 0000 0000',
    'FI00 0000 0000 0000 00',
    'FO00 0000 0000 0000 00',
    'FR00 0000 0000 00## #### #### #00',
    'GB00 aaaa 0000 0000 0000 00',
    'GI00 aaaa #### #### #### ###',
    'GL00 0000 0000 0000 00',
    'GR00 0000 000# #### #### #### ###',
    'HR00 0000 0000 0000 0000 0',
    'HU00 0000 0000 0000 0000 0000 0000',
    'IE00 #### 0000 0000 0000 00',
    'IS00 0000 0000 0000 0000 0000 00',
    'IT00 a000 0000 000# #### #### ###',
    'LI00 0000 0### #### #### #',
    'LT00 0000 0000 0000 0000',
    'LU00 000# #### #### ####',
    'LV00 aaaa #### #### #### #',
    'MC00 0000 0000 00## #### #### #00',
    'MT00 aaaa 0000 0### #### #### #### ###',
    'NL00 aaaa 0000 0000 00',
    'NO00 0000 0000 000',
    'PL00 0000 0000 0000 0000 0000 0000',
    'PT00 0000 0000 0000 0000 0000 0',
    'RO00 aaaa #### #### #### ####',
    'SE00 0000 0000 0000 0000 0000',
    'SI00 0000 0000 0000 000',
    'SK00 0000 0000 0000 0000 0000',
    'SM00 a000 0000 000# #### #### ###',
  ];

  const element = document.querySelector('[data-format-iban]');
  if (element) {
    const toUpper = (str) => str.toUpperCase();
    const definitions = {
      '#': /[0-9A-Z]/,
    };

    const ibanMask = IMask(element, {
      mask: SEPA_LIST.map((code) => {
        const res = {};
        res.mask = `aa${code.substring(2)}`;
        res.prepare = toUpper;
        res.definitions = definitions;
        res.countryCode = code.substring(0, 2);
        return res;
      }),
      dispatch(appended, dynamicMasked) {
        const current = (
          dynamicMasked.value + appended.toUpperCase()
        ).substring(0, 2);

        return dynamicMasked.compiledMasks.find((m) =>
          m.countryCode.startsWith(current)
        );
      },
    });

    const event = new Event ('ibanValidInput');
    const triggerIbanEvent = () => element.dispatchEvent(event);
    ibanMask.on("accept", triggerIbanEvent);
  }
});
