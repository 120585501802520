import domReady from '../../shared/utils/dom_ready';
import scrollit from '../../shared/utils/scrollit';

domReady(() => {
  if (typeof URLSearchParams === 'undefined') return;

  const params = new URLSearchParams(window.location.search);

  if (!params.has('p')) return;

  const anchorName = params.get('p');

  const anchor = document.querySelector(`[js_anchor = "${anchorName}"]`);

  if (anchor) {
    setTimeout(() => {
      scrollit(anchor, 600, false);
    }, 500);
  }
});
