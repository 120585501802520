import domReady from '../../shared/utils/dom_ready';

domReady(() => {
  if (typeof IntersectionObserver === 'undefined') return;

  const scope = document.getElementById('sticky-button-scope');

  if (!scope) return;

  const button = document.getElementById('sticky-button');

  if (!button) return;

  new IntersectionObserver((entries) => {
    button.classList.toggle('is-active', entries[0].isIntersecting);
  }).observe(scope);
});
