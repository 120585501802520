import domReady from '../../shared/utils/dom_ready';

// payment method buttons
let debitButton;
let paypalButton;

// payment method screens
let debitScreen;
let paypalScreen;

// the amount input (type in number) element
let input;

// the range input (slider) element
let rangeInput;

// the ratio between amount for organization and amount for bge
let ratio;

// keep track of the former input value, so we can revert to it after change
let formerInputValue;

// keep track of the formerly active button, so we can re-select it
let formerlyActiveButton;

// range input

const updateRangeInputLabels = (amountOrganization, amountBge) => {
  const organizationEl = document.getElementById(
    'debit-mandate-configurator__amount-organization'
  );
  organizationEl.innerText = amountOrganization;

  const bgeEl = document.getElementById(
    'debit-mandate-configurator__amount-bge'
  );
  bgeEl.innerText = amountBge;
};

const updateHiddenAmountOrganizationField = (amount) => {
  document.getElementById('debit_mandate_amount_organization').value = amount;
};

// update the range input, update all related labels and fields
const updateRangeInput = (amountTotal) => {
  if (amountTotal) {
    const newValue = Math.round(amountTotal * ratio);
    rangeInput.max = amountTotal;
    rangeInput.value = newValue;
  }

  const amountBge = rangeInput.value;
  const amountOrganization = rangeInput.max - amountBge;

  updateRangeInputLabels(amountOrganization, amountBge);
  updateHiddenAmountOrganizationField(amountOrganization);
};

const onRangeInputChange = () => {
  updateRangeInput();

  // only store/update the ratio when the user actually changes is via the range input
  ratio = rangeInput.value / rangeInput.max;
};

// update the hidden form field holding the amount_total
const updateHiddenAmountTotalField = (amount) => {
  const el = document.getElementById('debit_mandate_amount_total');
  el.value = amount;
};

// update submit button to show the current total amount
const updateSubmitButton = (amount) => {
  const button = document.querySelector(
    '#debit-mandate-configurator input[type=submit]'
  );
  button.value = button.value.replace(/\d+/, amount);
};

// whenever the total amount changes this method updates all places
const onAmountChange = (amount) => {
  updateRangeInput(amount);
  updateHiddenAmountTotalField(amount);
  updateSubmitButton(amount);
};

// just give back the active (currently selected) button
const activeButton = () =>
  document.querySelector(
    '#debit-mandate-configurator__buttons button.button--primary'
  );

// select (make active) the given button
const selectButton = (button) => {
  button.classList.remove('button--secondary');
  button.classList.add('button--primary');

  onAmountChange(button.dataset.amount);
};

// unselect the active button, e.g. when using the input field
const unselectActiveButton = () => {
  const button = activeButton();

  if (button) {
    formerlyActiveButton = button;
    button.classList.add('button--secondary');
    button.classList.remove('button--primary');
  }
};

// input field needs certain css classes depending on it's state for correct visual appearance
const updateInputAppearance = () => {
  // toggle visual active state
  if (
    input.value.length > 0 &&
    (input === document.activeElement || !activeButton())
  ) {
    input.classList.add('is-active');
  } else {
    input.classList.remove('is-active');
  }

  // visual length
  input.className = input.className.replace(/is-filled--\d+/, '');
  input.classList.add(`is-filled--${input.value.length}`);

  formerInputValue = input.value;
};

// user clicked a amount button
const onButtonClick = (e) => {
  unselectActiveButton();
  selectButton(e.target);

  updateInputAppearance();
};

// user entered the input field
const onInputFocus = () => {
  if (input.value === input.dataset.initialValue) {
    input.value = '';
  } else if (input.value.length > 0) {
    unselectActiveButton();
    onAmountChange(input.value);
  }

  updateInputAppearance();
};

// user types into the input field (also triggered on pasting)
const onInputType = () => {
  // don't allow any invalid content, only numbers between 1 and 2000
  const validAmount = /^([1-9][0-9]{0,2})$|^(1[0-9]{3})$|^2000$/;
  if (input.value.length > 0 && !input.value.match(validAmount)) {
    input.value = formerInputValue;
  } else if (input.value.length === 0) {
    // when empty, go back to formerly selected button
    selectButton(formerlyActiveButton);
  } else {
    // valid input
    unselectActiveButton();
    onAmountChange(input.value);
  }

  updateInputAppearance();
};

// user leaves the input field
const onInputBlur = () => {
  if (input.value.length === 0) {
    input.value = input.dataset.initialValue;
  }

  updateInputAppearance();
};

const onDebitClicked = () => {
  paypalButton.classList.remove('button--primary');
  paypalButton.classList.add('button--secondary');

  debitButton.classList.remove('button--secondary');
  debitButton.classList.add('button--primary');

  paypalScreen.style.display = 'none';
  debitScreen.style.display = 'block';
};

const onPaypalClicked = () => {
  debitButton.classList.remove('button--primary');
  debitButton.classList.add('button--secondary');

  paypalButton.classList.remove('button--secondary');
  paypalButton.classList.add('button--primary');

  debitScreen.style.display = 'none';
  paypalScreen.style.display = 'block';
};

domReady(() => {
  if (!document.querySelector('#debit-mandate-configurator')) return;

  debitScreen = document.getElementById('debit-mandate-configurator__debit');
  paypalScreen = document.getElementById('debit-mandate-configurator__paypal');

  debitButton = document.getElementById(
    'debit-mandate-configurator__payment-method--debit'
  );
  paypalButton = document.getElementById(
    'debit-mandate-configurator__payment-method--paypal'
  );

  if (paypalButton) {
    debitButton.addEventListener('click', onDebitClicked, false);
    paypalButton.addEventListener('click', onPaypalClicked, false);
  }

  // buttons 10€, 25€, 50€
  document
    .querySelectorAll('#debit-mandate-configurator__buttons button')
    .forEach((button) => {
      button.addEventListener('click', onButtonClick, false);
    });

  // input field for custom total amount
  input = document.getElementById('debit-mandate-configurator__input');
  input.addEventListener('focus', onInputFocus, false);
  input.addEventListener('blur', onInputBlur, false);
  input.addEventListener('input', onInputType, false);
  updateInputAppearance(); // initialize correct appearance

  // euro sign suffix of input field
  document
    .querySelector('.debit-mandate-configurator__input-euro')
    .addEventListener(
      'click',
      () => {
        input.focus();
      },
      false
    );

  // range input to control the distribution
  rangeInput = document.getElementById('debit_mandate_amount_bge');
  rangeInput.addEventListener('change', onRangeInputChange, false);
  rangeInput.addEventListener('input', onRangeInputChange, false);
  onRangeInputChange(); // initialize correct appearance
});
