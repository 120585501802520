import domReady from '../utils/dom_ready';
import inViewport from '../utils/in_viewport';

domReady(() => {
  document.querySelectorAll('[data-animate-counter]').forEach((el) => {
    const duration = el.dataset.animateCounter;
    let finalNumber = parseInt(el.textContent.replace(/\./g, ''), 10);

    let animationStarted;

    el.changeFinalNumber = (number) => {
      finalNumber = number;
    };

    const animate = (time) => {
      if (!animationStarted) animationStarted = time;
      const timeElapsed = time - animationStarted;
      const progressInPercentage = timeElapsed / duration;

      const number = Math.min(
        Math.floor(progressInPercentage * finalNumber),
        finalNumber
      );
      el.textContent = number.toLocaleString('de-DE');

      if (progressInPercentage < 1) window.requestAnimationFrame(animate);
    };

    // need to wait a program cycle, as otherwise Firefox sometimes
    // doesn't detect intersection when element already in viewport on page load
    setTimeout(() => {
      inViewport(el, () => {
        setTimeout(() => {
          el.classList.add('is-active');
          el.style.width = `${el.offsetWidth}px`;
          window.requestAnimationFrame(animate);
        }, 500);
      });
    }, 1);
  });
});
