/**
 * Calls the callback as soon as the DOM is ready.
 *
 * @param {Function} callback
 */

const domReady = (callback) => {
  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    callback();
    return;
  }

  document.addEventListener('DOMContentLoaded', callback);
};

export default domReady;
