import domReady from '../../shared/utils/dom_ready';

domReady(() => {
  const journalContentTitle = document.querySelectorAll('.journal-content h2');
  const journalTeaserTitle = document.querySelectorAll(
    '.journal-teaser__title h3',
  );
  const journalDetailTitle = document.querySelectorAll(
    '.journal-detail__title h1',
  );

  const wrapStrings = (el) => {
    const wrappedString = el.innerText.replace(
      /([A-Za-z0-9,.!?ÜüÄäÖöß»«'<>:/]+)/g,
      '<span>$1</span>',
    );
    el.innerHTML = wrappedString;
  };

  const countUnique = (iterable) => new Set(iterable).size;

  const lineCount = (el) => {
    const spans = el.querySelectorAll('span');
    const spanOffsets = [];
    spans.forEach((item) => {
      spanOffsets.push(item.getBoundingClientRect().top);
    });
    return countUnique(spanOffsets);
  };

  journalContentTitle.forEach((item) => {
    wrapStrings(item);
  });

  journalTeaserTitle.forEach((item) => {
    wrapStrings(item);
    setTimeout(() => {
      const lines = lineCount(item);
      if (lines > 1) {
        item.parentNode.classList.add(`journal-teaser__title--${lines}`);
      }
    }, 0);
  });

  journalDetailTitle.forEach((item) => {
    wrapStrings(item);
    setTimeout(() => {
      if (lineCount(item) > 2) {
        item.classList.add('journal-headline--long');
      }
    }, 0);
  });
});
