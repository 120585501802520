import domReady from '../../shared/utils/dom_ready';

domReady(() => {
  const tabs = document.querySelectorAll('[data-tab]');
  const tabElements = document.querySelectorAll('[data-tab-content]');

  const toggleTabs = (target) => {
    tabs.forEach((item) => {
      item.classList.remove('is-active');
    });
    target.classList.add('is-active');
  };

  const toggleContent = (targetElements) => {
    tabElements.forEach((item) => {
      item.classList.remove('is-active');
    });
    targetElements.forEach((item) => {
      item.classList.add('is-active');
    });
  };

  tabs.forEach((item) => {
    const tabValue = item.getAttribute('data-tab');
    const targetElements = document.querySelectorAll(
      `[data-tab-content=${tabValue}]`
    );
    item.addEventListener('click', () => {
      toggleTabs(item);
      toggleContent(targetElements);
    });
  });

  const tabDeepLink = () => {
    if (typeof URLSearchParams === 'undefined') return;
    const params = new URLSearchParams(window.location.search);
    if (!params.has('item')) return;
    const tabValue = params.get('item');
    const tabItem = document.querySelector(`[data-tab="${tabValue}"]`);
    if (tabItem) {
      toggleTabs(tabItem);
      const tabContent = document.querySelectorAll(`[data-tab-content="${tabValue}"]`);
      toggleContent(tabContent);
    };
  }
  tabDeepLink();
});
