import domReady from '../../shared/utils/dom_ready';

const update = (el, elementsToUncheck) => {
  if (el.checked) {
    elementsToUncheck.forEach((elToUncheck) => {
      elToUncheck.checked = false;
    });
  }
};

domReady(() => {
  const elements = document.querySelectorAll('[data-uncheck-if-checked]');

  elements.forEach((el) => {
    const id = el.dataset.uncheckIfChecked;
    const elementsToUncheck = [
      ...document.querySelectorAll(`#${id}`),
    ];

    update(el, elementsToUncheck);

    el.addEventListener(
      'change',
      () => {
        update(el, elementsToUncheck);
      },
      false
    );
  });
});
